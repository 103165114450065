// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-accessibility-jsx": () => import("./../../../src/pages/accessibility.jsx" /* webpackChunkName: "component---src-pages-accessibility-jsx" */),
  "component---src-pages-curriculum-jsx": () => import("./../../../src/pages/curriculum.jsx" /* webpackChunkName: "component---src-pages-curriculum-jsx" */),
  "component---src-pages-facilitators-jsx": () => import("./../../../src/pages/facilitators.jsx" /* webpackChunkName: "component---src-pages-facilitators-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-land-acknowledgement-jsx": () => import("./../../../src/pages/land-acknowledgement.jsx" /* webpackChunkName: "component---src-pages-land-acknowledgement-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-one-oh-one-jsx": () => import("./../../../src/pages/one-oh-one.jsx" /* webpackChunkName: "component---src-pages-one-oh-one-jsx" */),
  "component---src-pages-references-jsx": () => import("./../../../src/pages/references.jsx" /* webpackChunkName: "component---src-pages-references-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */)
}

